import {debounce} from 'lodash'
import PropTypes from 'prop-types'
import React, {useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useGetChatRoomsQuery} from '../../../services/ChatRoom'
import Spinner from '../../Spinner/Spinner'
import SearchInput from '../../UI/SearchInput/SearchInput'
import Chat from '../Chat/Chat'
import ChatEmpty from '../ChatEmpty/ChatEmpty'
import UserList from '../UserList/UserList'
import classes from './Messages.module.scss'


const Messages = ({userId}) => {
  const initialQuery = {user_id: userId}
  const [query, setQuery] = useState(initialQuery)
  const {id} = useParams()
  const initialChatRomId = id ? Number(id) : null
  const [selectedChatRoomId, setSelectedChatRoomId] =
    useState(initialChatRomId)
  const {data, isFetching} = useGetChatRoomsQuery(query, {
    skip: !userId,
  })

  const handleAssociateChange = (chatRoomId) => {
    if (chatRoomId !== selectedChatRoomId) {
      setSelectedChatRoomId(chatRoomId)
    }
  }

  const handleSearchInput = (value) => {
    setQuery((prevValue) => ({
      ...prevValue,
      s: value,
    }))
  }

  const debounceHandleSearchInput = useMemo(
    () => debounce(handleSearchInput, 300),
    []
  )

  return (
    <div className={classes.Container}>
      <div className={classes.Columns}>
        <div className={classes.Menu}>
          <div className={classes.Wrapper}>
            <div className={classes.SearchBox}>
              <SearchInput
                onChange={debounceHandleSearchInput}
                placeholder="Search"
              />
            </div>
            {isFetching && <Spinner />}
            {!isFetching && (
              <UserList
                list={data}
                activeChatRoomId={selectedChatRoomId}
                onChange={handleAssociateChange}
              />
            )}
          </div>
        </div>
        {selectedChatRoomId && <Chat chatRoomId={selectedChatRoomId} />}
        {!selectedChatRoomId && <ChatEmpty />}
      </div>
    </div>
  )
}

Messages.defaultProps = {
  userId: '',
}

Messages.propTypes = {
  userId: PropTypes.string,
}

export default Messages
