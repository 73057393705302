import {Api} from './Api'

const InvoiceApi = Api.injectEndpoints({
  endpoints: (build) => ({
    showInvoice: build.query({
      query: (id) => `invoice/${id}`,
      providesTags: ['Invoice'],
    }),
    getInvoice: build.query({
      query: (query) => `/invoice?${query}`,
      providesTags: ['Invoice'],
    }),
    destroyInvoice: build.mutation({
      query: (id) => ({
          url: `/invoice/${id}`,
          method: 'DELETE',
        }),
      invalidatesTags: ['Invoice'],
    }),
    updateInvoice: build.mutation({
      query: (data) => ({
          url: `/invoice/${data.id}`,
          method: 'PUT',
          body: data,
        }),
      invalidatesTags: ['Invoice'],
    }),
    storeInvoice: build.mutation({
      query: (data) => ({
          url: '/invoice',
          method: 'POST',
          body: data,
        }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ['Invoice'],
    }),

  }),
  overrideExisting: false,
})

export const {
  useGetInvoiceQuery,
  useShowInvoiceQuery,
  useDestroyInvoiceMutation,
  useUpdateInvoiceMutation,
  useStoreInvoiceMutation,
} = InvoiceApi
