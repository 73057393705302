import {createSlice} from '@reduxjs/toolkit'
import {Views} from 'react-big-calendar'
const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    view: Views.MONTH,
  },
  reducers: {
    storeView: (state, param) => {
      const {payload} = param;
      state.view = payload;
    },
  }
});
const {actions, reducer} = calendarSlice
export const {storeView} = actions;
export default reducer;
