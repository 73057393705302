// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S7OR\\+0P2MRGqW6V4dBfyxQ\\=\\={--tw-bg-opacity:1;background-color:rgb(255 255 255/var(--tw-bg-opacity));border-radius:.75rem;box-shadow:0 4px 16px rgba(148,163,184,.15);display:flex;margin-bottom:1rem;padding:2rem}.k5PZwZ4FiRnx7QmcDvhtwQ\\=\\=:first-child{--tw-border-opacity:1;border-color:rgb(229 229 229/var(--tw-border-opacity));border-right-width:1px;border-style:solid;padding-right:2rem;width:70%}.k5PZwZ4FiRnx7QmcDvhtwQ\\=\\=:last-child{padding-left:2rem;width:30%}.k5PZwZ4FiRnx7QmcDvhtwQ\\=\\= h2{--tw-border-opacity:1;border-bottom-width:1px;border-color:rgb(229 229 229/var(--tw-border-opacity));border-style:solid;margin-bottom:1rem;padding-bottom:.5rem}.k5PZwZ4FiRnx7QmcDvhtwQ\\=\\=>div:last-child{margin-bottom:0}", "",{"version":3,"sources":["webpack://./resources/js/pages/InvoiceNew/InvoiceNew.module.scss"],"names":[],"mappings":"AAAA,6BAGE,iBAAA,CAAA,sDAAA,CACA,oBAAA,CAHA,2CAAA,CACA,YAAA,CAIA,kBAAA,CADA,YAEF,CAII,wCAAA,qBAAA,CAAA,sDAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,kBAAA,CACA,SAFA,CAMA,uCAAA,iBAAA,CACA,SADA,CAKA,+BAAA,qBAAA,CAAA,uBAAA,CAAA,sDAAA,CAAA,kBAAA,CACA,kBAAA,CAAA,oBADA,CAMC,2CAAA,eAAA","sourcesContent":[".NewInvoice {\n  box-shadow: 0px 4px 16px rgba(148, 163, 184, 0.15);\n  @apply flex;\n  @apply bg-white;\n  @apply rounded-xl;\n  @apply p-8;\n  @apply mb-4;\n}\n\n.NewInvoiceCol {\n  &:first-child {\n    @apply border-r border-black-10 border-solid;\n    @apply pr-8;\n    width: 70%;\n  }\n\n  &:last-child {\n    @apply pl-8;\n    width: 30%;\n  }\n\n  h2 {\n    @apply border-b border-black-10 border-solid;\n    @apply pb-2 mb-4;\n  }\n\n  > div {\n    &:last-child {\n     @apply mb-0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NewInvoice": "S7OR+0P2MRGqW6V4dBfyxQ==",
	"NewInvoiceCol": "k5PZwZ4FiRnx7QmcDvhtwQ=="
};
export default ___CSS_LOADER_EXPORT___;
