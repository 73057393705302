import React, {useState} from 'react'
import {useGetGoalSetQuery} from "../../services/GoalSet";
import Select from './InvoiceInput/Select'
import Input from './InvoiceInput/Input'
import classes from './Invoices.module.scss'


const NewInvoice = ({i, register, errors, remove, id}) => {
  const {data: goalSets} = useGetGoalSetQuery();
  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState(0);
  return (
    <div className='flex flex-row gap-4 items-center'>
      <div className="form-row">
      <label>Goal Set</label>     
      <div className="flex gap-2">
        <Select
          name={`items.${i}.goal_set`}
          register={register}
          values={goalSets?.data?.map(goalSet => goalSet.title)}
          errors={errors}
        />
     
        <Input
          name={`items.${i}.qty`}
          onChange={setQty}
          register={register}
          errors={errors}
          value={1}
          placeholder="QTY"
          readOnly
        />
      
        {
        //   <Input
        //   onChange={setQty}
        //   name={`items.${i}.qty`}
        //   value={1}
        //   readOnly
        //   register={register}
        //   placeholder="Quantity"
        //   errors={errors}
        //   label="Reward Receive by date"
        //   type='number'
        // />
          }
        <Input
          onChange={setPrice}
          name={`items.${i}.price`}
          min={1}
          value={10}
          readOnly
          register={register}
          placeholder="Price"
          errors={errors}
          type='number'
        />
      </div>
      <div>
        <Select
          name={`items.${i}.description`}
          register={register}
          placeholder="Activity Numver"
          errors={errors}
          values={["1.1","1.2","1.3","1.4","1.5","2.1","2.2","2.3","2.4","2.5","3.1","3.2","3.3","3.4","3.5","4.1","4.2","4.3","4.4","4.5"]}
        />
      </div>
      <div className="flex justify-end">
        Amount:<b>&nbsp;$10</b>
      </div>
      </div>
      <div className="flex items-center mb-10">
          <button type="button" className={classes.Trash} onClick={() => remove(id)}>
            <i className="icon-trash"></i>
          </button>
        </div>
    </div>
  )
}


export default NewInvoice
