import React from 'react'
import classes from './Spinner.module.scss'

const Spinner = () => (
  <div className={classes.SpinnerContainer}>
    <div className={classes.SpinnerContent} role="status" />
  </div>
)

export default Spinner
