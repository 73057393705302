import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {LOCAL_STORAGE_ITEM_NAMES} from '../constants'

const baseUrl = 'https://www.googleapis.com/drive/v3/files'
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    headers.set('authorization', `Bearer ${localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.GoogleToken)}`)
    return headers
  },
})


export const GoogleDriveApi = createApi({
  reducerPath: 'googleDrive',
  baseQuery,
  endpoints: (build) => ({
    getMyFiles: build.query({
      query: () => `${baseUrl}?q=${encodeURI('\'me\' in owners')}`,
    }),
    copyFile: build.query({
      query: (query) => ({
        url: `https://www.googleapis.com/drive/v3/files/${query.id}/copy`,
        method: 'POST',
        body: {
          name: `${query.userEmail}_${query.fileName}`,
        },
        // baseUrl
      }),
    }),
    shareFileWithTasselTurnMembers: build.mutation({
      query: (query) => ({
        url: `https://www.googleapis.com/drive/v2/files/${query.id}/permissions`,
        method: 'POST',
        body: {
          type: 'domain',
          role: 'reader',
          value: 'tasselturn.org',
        },
      }),
    }),
  }),
})

export const {useCopyFileQuery, useGetMyFilesQuery, useShareFileWithTasselTurnMembersMutation} = GoogleDriveApi
