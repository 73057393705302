import React from 'react'
import classes from './Modal.module.scss'
import {func, node, bool} from "prop-types";

const Modal = ({children, close, modalState, extraClass, canBeClosed=true}) => {

  if (!modalState) {
    return null;
  }

  return (
    <div className={classes.ModalWrapper}>
      <div className={`${classes.Modal} ${extraClass}`}>
        {canBeClosed && <b href="" className={classes.ModalClose} onClick={close}>
          <i className="icon-x"/>
        </b>}
        {children}
      </div>
    </div>
  )
}

Modal.propTypes = {
  close: func.isRequired,
  children: node.isRequired,
  modalState: bool.isRequired
}

Modal.defaultProps = {
  extraClass: '',
}

export default Modal