import React from 'react'
import {string, func, array} from 'prop-types'
import {checkRecursive} from "../../../utils/helper";


const Select = ({register, label, name, values, onChange, errors}) => {

  return (
    <select className={(checkRecursive(name, errors) ? "form-error " : "")}
            {...register(name, {required: true, onChange: (e) => onChange(e.target.value)})}>
      {label && <option value=''>{label}</option>}
      {values.map((value, i) => <option key={i} value={value}>{value}</option>)}
    </select>
  )
}

Select.propTypes = {
  register: func.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  values: array.isRequired,
  onChange: func.isRequired,
}

Select.defaultProps = {
  onChange: () => {
  },
  values: [],
  label: ''
}

export default Select

