import React from 'react'
import {string, func, array, object} from 'prop-types'
import {checkRecursive} from "../../../utils/helper";


const Input = ({register, label, name, placeholder, type, onChange, errors, min, max, readOnly, value}) => (
  <>
    {label && <label>{label}</label>}
    <input
      min={min}
      max={max}
      type={type}
      value={value}
      placeholder={placeholder}
      readOnly={readOnly ? readOnly : false}
      className={(checkRecursive(name, errors)  ? "form-error" : "")}
      {...register(name, {required: true, onChange: (e) => onChange(e.target.value)})}
    />
  </>
)

Input.propTypes = {
  register: func.isRequired,
  name: string.isRequired,
  placeholder: string.isRequired,
  type: string.isRequired,
  onChange: func.isRequired,
  errors: object.isRequired
}

Input.defaultProps = {
  type: 'text',
  onChange: () => {},
  errors: {}
}

export default Input
