import EchoClient from '../utils/EchoClient'
import {Api} from './Api'

const chatRoomResourceUrl = '/chat'

const ChatRoomApi = Api.injectEndpoints({
  endpoints: (build) => ({
    getChatRooms: build.query({
      query: (query) => {
        const urlParams = new URLSearchParams(query)

        return `${chatRoomResourceUrl}?${urlParams.toString()}`
      },
      transformResponse: (response) => response.data,
      providesTags: ['ChatRoom'],
    }),

    getChatNewMessages: build.query({
      query: (chatRomId) => `${chatRoomResourceUrl}/${chatRomId}/new_messages`,
    }),

    getChatRoomMessages: build.query({
      query: (data) => `${chatRoomResourceUrl}/${data.chatRoomId}/messages?${data.query}`,
      transformResponse: (response) => response.data,
      async onCacheEntryAdded(query, {updateCachedData, cacheDataLoaded, cacheEntryRemoved}) {
        const channel = EchoClient.join(`App.Chat.${query.chatRoomId}`)

        try {
          await cacheDataLoaded

          const listener = (event) => {
            const {data} = event
            updateCachedData((draft) => {
              draft.push(data)
            })
          }

          channel.listen('.messageCreated', listener)
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        await cacheEntryRemoved
        channel.subscription.disconnect()
      },
    }),
    storeChatMessage: build.mutation({
      query: (data) => ({
        url: `${chatRoomResourceUrl}/${data.get('id')}/messages`,
        method: 'POST',
        body: data,
      }),
      // transformResponse: (response) => response.data,
    }),
    storeChatRoom: build.mutation({
      query: (data) => ({
        url: chatRoomResourceUrl,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['ChatRoom'],

    }),
  }),
  overrideExisting: false,
})

export const {
  useGetChatRoomsQuery,
  useGetChatRoomMessagesQuery,
  useLazyGetChatRoomMessagesQuery,
  useStoreChatMessageMutation,
  useStoreChatRoomMutation,
  useGetChatNewMessagesQuery,
} = ChatRoomApi
