import React from 'react'
import PropTypes from 'prop-types'
import classes from '../../Chat/Chat.module.scss'

const MessageFile = (props) => {
  const {name, url, type} = props

  return (
    <>
      <a href={url} className={classes.MessageFile} download>
        {type.includes('image') ? <img src={url} /> : <i className="icon-file-download" />}
      </a>
      <p className={classes.MessageFileName}>{name}</p>
    </>
  )
}

MessageFile.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
}

export default MessageFile
