export const checkRecursive = (name, object) => {
  const keys = name.split('.')
  let response = false
  for (let i = 0; i < keys.length; i++) {
    if (object.hasOwnProperty(keys[i])) {
      object = object[keys[i]]
      response = true
    } else {
      response = false
    }
  }
  return response
}

export const getTodaysDate = ()=> {
  return new Date().toISOString().split('T')[0];
}

export const addHours = ({date, hours}) => {
  // 👇 Make copy with "Date" constructor.
  const dateCopy = new Date(date);

  dateCopy.setHours(dateCopy.getHours() + hours);

  return dateCopy;
}