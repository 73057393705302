// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K\\+MgiXPyI9HuHI1759hZFA\\=\\={align-items:center;background-color:#335ee8;border-radius:1.5rem;color:#fff;display:flex;height:16px;justify-content:center;min-width:16px;padding:0 4px}.K\\+MgiXPyI9HuHI1759hZFA\\=\\=>em{font-size:.75rem;font-style:normal;font-weight:500;line-height:1rem}", "",{"version":3,"sources":["webpack://./resources/js/components/chat/UserItem/NewMessagesBadge/NewMessagesBadge.module.scss"],"names":[],"mappings":"AACE,6BAOA,kBAAA,CANA,wBAAA,CADA,oBAAA,CAEA,UAAA,CAIA,YAAA,CAFA,WAAA,CAIA,sBAAA,CALA,cAAA,CAEA,aALA,CAWE,gCAEA,gBAAA,CAFA,iBAAA,CACA,eAAA,CACA,gBAFA","sourcesContent":[".UserMessagesCount {\n  @apply rounded-3xl;\n  background-color: #335EE8;\n  color: #ffffff;\n  min-width: 16px;\n  height: 16px;\n  padding: 0 4px;\n  display: flex;\n  @apply items-center;\n  @apply justify-center;\n\n  &>em {\n    @apply not-italic;\n    @apply font-medium;\n    @apply text-xs;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserMessagesCount": "K+MgiXPyI9HuHI1759hZFA=="
};
export default ___CSS_LOADER_EXPORT___;
