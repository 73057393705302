import React, {useEffect, useState} from 'react'
import {number} from 'prop-types'
import classes from '../Chat/Chat.module.scss'
import SendIcon from '../../../../img/icons/icon-send.svg'
import usePrevious from '../../../hooks/usePrevious'
import {useStoreChatMessageMutation} from '../../../services/ChatRoom'
import ChatInputFile from "./ChatInputFile/ChatInputFile";
import {useMeQuery} from '../../../services/User'


const initialMessageValue = ''

const ChatInput = (props) => {
  const {data: me, isLoading, error} = useMeQuery()
  const {chatRoomId} = props
  const [messageValue, setMessageValue] = useState(initialMessageValue)
  const prevChatRoomId = usePrevious(chatRoomId)
  const [storeChatMessage] = useStoreChatMessageMutation()
  const [files, setFiles]= useState([]);

  const handleMessageSubmit = async () => {
    const newMessage = new FormData()
    files?.map((file) => {
      newMessage.append('files[]', file)
    })
    newMessage.append('id', chatRoomId)
    newMessage.append('message', messageValue)
    await storeChatMessage(newMessage).unwrap()
    await storeEvent()
    setMessageValue(initialMessageValue)
    setFiles([])
    
  }

  const storeEvent = () =>{
    let role = {
      1: 'student',
      2: 'coach',
      3: 'admin'
    }

    try {
      gtag("event", "message_sent", {
        method: "In_App",
        custom_map: {
          'role': role[me.role],
          'userName': me.name
       }
        });
    } catch (err) {
      console.log('err', err)
    }
  }
  const onFileChange = event => {
    setFiles([...event.target.files])
  }

  const removeFile = (index) => {
    const filesArray = [...files];
    filesArray.splice(index, 1);
    setFiles(filesArray)
  }

  const handleMessageInput = (e) => {
    setMessageValue(e.target.value)
  }

  useEffect(() => {
    if (prevChatRoomId !== chatRoomId) {
      setMessageValue(initialMessageValue)
    }
  }, [chatRoomId, prevChatRoomId])

  return (
    <>
      <div className={classes.InputFilesArea}>
        {files && files.map((file, index) => <ChatInputFile name={file.name} calback={removeFile} index={index}/>)}
      </div>
      <div className={classes.InputBlockInner}>
        <textarea
          key={chatRoomId}
          className={classes.ChatInput}
          value={messageValue}
          onChange={handleMessageInput}
          placeholder="Type a message..."
          rows={3}
        >
        </textarea>

        <div className={classes.ChatActions}>
          <label htmlFor='image' className={classes.CustomFileInput}>
            <input
              type="file"
              id='image'
              multiple={true}
              accept="image/jpeg,image/gif,image/png,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
              className='hidden'
              onChange={onFileChange}
            />
          </label>
          <button type="button" title="Send" onClick={handleMessageSubmit} disabled={!messageValue}>
            <img src={SendIcon} alt="Send" />
          </button>
        </div>
      </div>
    </>
  )
}

ChatInput.propTypes = {
  chatRoomId: number.isRequired,
}

export default ChatInput
