import React, {lazy, Suspense} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {ADMIN, STUDENT, COACH} from './utils/userRoles'
import Spinner from './components/Spinner/Spinner'
import MainLayoutRoute from './components/MainLayout/MainLayoutRoute'
import InvoiceNew from './pages/InvoiceNew/InvoiceNew'
import ChatLogs from './pages/ChatLogs/ChatLogs'

const HomePage = lazy(() => import('./pages/HomePage/HomePage'))
const CoachDashboard = lazy(() => import('./pages/CoachDashboard/CoachDashboard'))
const Messages = lazy(() => import('./pages/Messages/Messages'))
const GoalSets = lazy(() => import('./pages/GoalSets/GoalSets'))
const GoalSetNew = lazy(() => import('./pages/GoalSetNew/GoalSetNew'))
const GoalSetDetails = lazy(() => import('./pages/GoalSetDetails/GoalSetDetails'))
const Invoices = lazy(() => import('./pages/Invoices/Invoices'))
const Invoice = lazy(() => import('./pages/Invoice/Invoice'))
const Students = lazy(() => import('./pages/Students/Students'))
const Student = lazy(() => import('./pages/Student/Student'))
const Applications = lazy(() => import('./pages/Applications/Applications'))
const Apply = lazy(() => import('./pages/Apply/Apply'))
const EAP = lazy(() => import('./pages/EAP/eap.js'))
const CheckAvailability = lazy(() => import('./pages/CheckAvailability/CheckAvailability'))
const Application = lazy(() => import('./pages/Application/Application'))
const Coaches = lazy(() => import('./pages/Coaches/Coaches'))
const PageNotFound = lazy(() => import('./pages/PageNotFound/PageNotFound'))
const PageAccessForbidden = lazy(() => import('./pages/PageAccessForbidden/PageAccessForbidden'))
const HelpCenter = lazy(() => import('./pages/HelpCenter/HelpCenter'))
const SessionsMonth = lazy(() => import('./pages/SessionsMonth/SessionsMonth'))
const MyAccount = lazy(() => import('./pages/MyAccount/MyAccount'))
const NewSession = lazy(() => import('./pages/NewSession/NewSession'))
const ProfileCoach = lazy(() => import('./pages/ProfileCoach/ProfileCoach'))
const ManageStudent = lazy(() => import('./pages/ManageStudent/ManageStudent'))
const Auth = lazy(() => import('./pages/Auth/Auth'))
const CoachStudents = lazy(() => import('./pages/CoachStudents/CoachStudents'))
const RewardLeaderboard = lazy(() => import('./pages/Leaderboards/RewardLeaderboard'))
const Routes = () => (
  <Router>
    <Switch>
      <MainLayoutRoute
        exact
        strict
        path="/"
        pageName='HomePage'
        pageTitle='Home'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <HomePage {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        strict
        allowedRoles={[COACH]}
        pageName='CoachDashboard'
        path="/coach_dashboard"
        pageTitle='TasselTurn Coach Dashboard'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <CoachDashboard {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        path="/my-account"
        allowedRoles={[COACH]}
        pageName='MyAccount'
        pageTitle='TasselTurn Coach Account'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <MyAccount {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        path="/messages/:id?"
        allowedRoles={[COACH, STUDENT]}
        pageName='Messages'
        pageTitle='TasselTurn | Messages'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <Messages {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        path="/chat-logs/:userId"
        allowedRoles={[ADMIN]}
        pageName='ChatLogs'
        pageTitle='TasselTurn | Chat Logs'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <ChatLogs {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/rewards"
        allowedRoles={[STUDENT, ADMIN]}
        pageName='Rewards'
        pageTitle='TasselTurn | Rewards'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <Invoices {...props} />
          </Suspense>
        )}
      />
       <MainLayoutRoute
        exact
        path="/leaderboards/rewards"
        allowedRoles={[STUDENT, ADMIN, COACH]}
        pageName='Leaderboard | Rewards'
        pageTitle='TasselTurn | Reward Leaderboard'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <RewardLeaderboard/>
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/reward/:id"
        allowedRoles={[ADMIN, STUDENT]}
        pageName='Reward'
        pageTitle='TasselTurn | Reward'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <Invoice {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/available-mentors"
        pageName='CheckAvailability'
        pageTitle='TasselTurn | Available Mentors'
        allowedRoles={[ADMIN, STUDENT, COACH]}
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <CheckAvailability {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/reward-new"
        pageName='RewardNew'
        pageTitle='TasselTurn | New Reward'
        allowedRoles={[STUDENT]}
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <InvoiceNew {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        allowedRoles={[ADMIN]}
        path="/admin-goal-sets"
        pageName='GoalSets'
        pageTitle='TasselTurn | Goal Sets'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <GoalSets {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/admin-goal-sets/new"
        allowedRoles={[ADMIN]}
        pageName='GoalSetNew'
        pageTitle='TasselTurn | New Goal Set'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <GoalSetNew {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/admin-goal-sets/:id"
        pageName='GoalSetDetails'
        pageTitle='TasselTurn | Goal Set Details'
        allowedRoles={[ADMIN]}
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <GoalSetDetails {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/students"
        pageName='Students'
        pageTitle='TasselTurn | Students'
        allowedRoles={[ADMIN, COACH]}
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <Students {...props} />
          </Suspense>
        )}
      />

      <MainLayoutRoute
        exact
        path="/students/:id"
        allowedRoles={[ADMIN]}
        pageName='Student'
        pageTitle='TasselTurn | Student'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <Student {...props} />
          </Suspense>
        )}
      />

      <MainLayoutRoute
        exact
        path="/applications"
        pageName='Applications'
        allowedRoles={[ADMIN]}
        pageTitle='TasselTurn | Applications'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <Applications {...props} />
          </Suspense>
        )}
      />

      <MainLayoutRoute
        exact
        path="/applications/:id"
        allowedRoles={[ADMIN]}
        pageName='Application'
        pageTitle='TasselTurn | Application'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <Application {...props} />
          </Suspense>
        )}
      />
      {/* <Route
        path="/apply"
        pageName='Apply'
        pageTitle='TasselTurn | Apply'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <Apply {...props} />
          </Suspense>
        )}
      /> */}
       <Route
        path="/EAP"
        pageName='EAP'
        pageTitle='TasselTurn | EAP'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <EAP {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/help-center"
        pageName='HelpCenter'
        pageTitle='TasselTurn | Help Center'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <HelpCenter {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/coaches"
        allowedRoles={[ADMIN]}
        pageName='Coaches'
        pageTitle='TasselTurn | Coaches'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <Coaches {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/newSession/:coachId"
        allowedRoles={[STUDENT]}
        pageName='NewSession'
        pageTitle='TasselTurn | New Session'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <NewSession {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/coaches/:coachId"
        pageName='ProfileCoach'
        allowedRoles={[STUDENT]}
        pageTitle='TasselTurn | Coach Profile'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <ProfileCoach {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/my-sessions"
        allowedRoles={[STUDENT]}
        pageName='SessionsMonth'
        pageTitle='TasselTurn | My Sessions'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <SessionsMonth {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/my-students"
        pageName='CoachStudents'
        pageTitle='TasselTurn | My Students'
        allowedRoles={[COACH, ADMIN]}
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <CoachStudents {...props} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        exact
        path="/my-students/:id"
        pageName='ManageStudent'
        pageTitle='TasselTurn | Manage Student'
        allowedRoles={[COACH, ADMIN]}
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <ManageStudent {...props} />
          </Suspense>
        )}
      />
      <Route
        exact
        path="/login"
        pageName='Auth'
        pageTitle='TasselTurn | Login'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
              <Auth {...props} />
          </Suspense>
        )}
      />
      <Route
        path="/403"
        pageName='PageAccessForbidden'
        pageTitle='TasselTurn | Access Forbidden'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <PageAccessForbidden {...props} />
          </Suspense>
        )}
      />
      <Route
        path="*"
        pageName='PageNotFound'
        pageTitle='TasselTurn | Page Not Found'
        render={(props) => (
          <Suspense fallback={<Spinner />}>
            <PageNotFound {...props} />
          </Suspense>
        )}
      />
    </Switch>

  </Router>
)

export default Routes
