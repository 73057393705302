// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LrZNYz1GQoVb65e4drUDJw\\=\\={align-items:center;display:flex;justify-content:center}@-webkit-keyframes G6u5kCg2NDWN1eeYKtKOxw\\=\\={to{transform:rotate(1turn)}}@keyframes G6u5kCg2NDWN1eeYKtKOxw\\=\\={to{transform:rotate(1turn)}}.\\+AX9LPwMiASOaGjYriC67Q\\=\\={--tw-text-opacity:1;-webkit-animation:G6u5kCg2NDWN1eeYKtKOxw\\=\\= 1s linear infinite;animation:G6u5kCg2NDWN1eeYKtKOxw\\=\\= 1s linear infinite;border-radius:9999px;border-width:4px;color:rgb(51 94 232/var(--tw-text-opacity));display:inline-block;height:2rem;width:2rem}", "",{"version":3,"sources":["webpack://./resources/js/components/Spinner/Spinner.module.scss"],"names":[],"mappings":"AACI,4BAEA,kBAAA,CAFA,YAAA,CACA,sBADA,CAMA,8CAAA,GAAA,uBAAA,CAAA,CAAA,sCAAA,GAAA,uBAAA,CAAA,CAAA,6BAMA,mBAAA,CANA,+DAAA,CAAA,uDAAA,CAKA,oBAAA,CADA,gBAAA,CAEA,2CAAA,CALA,oBAAA,CAEA,WAAA,CADA,UAFA","sourcesContent":[".SpinnerContainer {\n    @apply flex;\n    @apply justify-center;\n    @apply items-center;\n}\n\n.SpinnerContent {\n    @apply animate-spin;\n    @apply inline-block;\n    @apply w-8;\n    @apply h-8;\n    @apply border-4;\n    @apply rounded-full;\n    @apply text-blue-80;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SpinnerContainer": "LrZNYz1GQoVb65e4drUDJw==",
	"SpinnerContent": "+AX9LPwMiASOaGjYriC67Q==",
	"spin": "G6u5kCg2NDWN1eeYKtKOxw=="
};
export default ___CSS_LOADER_EXPORT___;
