import {createSlice} from '@reduxjs/toolkit';

const headerSlice = createSlice({
  name: "header",
  initialState: {
    title: '',
    components: [],
    reverse: null
  },
  reducers: {
    setTitle: (state, param) => {
      const {payload} = param;
      state.title = payload;
    },
    setReverse: (state, param) => {
      const {payload} = param;
      state.reverse = payload;
    },
    setComponents: (state, param) => {
      const {payload} = param;
      state.components =payload;
    },
  }
});
const {actions, reducer} = headerSlice
export const {setTitle, setComponents, setReverse} = actions;
export default reducer;
