import {useState, useEffect} from 'react'
import {ADMIN, STUDENT, COACH} from '../utils/userRoles'
import {useMeQuery} from '../services/User'

export const useUserRole = () => {
  const {data: me} = useMeQuery()
  const [isAdmin, setIsAdmin] = useState(false)
  const [isStudent, setIsStudent] = useState(false)
  const [isCoach, setIsCoach] = useState(false)

  useEffect(() => {
    if (me) {
      if (me?.role === ADMIN) {
        setIsAdmin(true)
      }
      if (me?.role === STUDENT) {
        setIsStudent(true)
      }
      if (me?.role === COACH) {
        setIsCoach(true)
      }
    }
  }, [me])

  return {isAdmin, isStudent, isCoach}
}