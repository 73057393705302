import React, {useEffect} from 'react'
import classes from './InvoiceNew.module.scss'
import {useForm, useFieldArray} from 'react-hook-form'
import {useStoreInvoiceMutation} from '../../services/Invoice'
import NewInvoice from '../../components/NewInvoice/NewInvoice'
import InvoiceTotal from '../../components/NewInvoice/InvoiceTotal/InvoiceTotal'
import Input from '../../components/NewInvoice/InvoiceInput/Input'
import {useHistory} from 'react-router-dom'
import {useMeQuery} from "../../services/User"
import { getTodaysDate } from '../../utils/helper'
import {checkRecursive} from "../../utils/helper";


const InvoiceNew = () => {
  const history = useHistory()
  const [saveInvoice] = useStoreInvoiceMutation()
  const {register, handleSubmit, control, formState: {errors}} = useForm()
  const {fields, append, remove} = useFieldArray({name: 'items', control})
  const newItem = () => (append({}))
  const todaysDate = getTodaysDate();
  const [checkbox, setCheckbox] = React.useState(false)

  const {data: me} = useMeQuery();

  const handleCheckboxClick = () => {
    setCheckbox(!checkbox); // Toggle checkbox value
  };

  const onSubmit = async (data) => {
    data.bill_from_email = me?.email
    data.bill_to = 'admin@tasselturn.org'
    data.coachreward = checkbox
    data.discount = 10
    console.log('data', data)

    try {
      const invoiceResponse = await saveInvoice(data).unwrap()
      let role = {
        1: 'student',
        2: 'coach',
        3: 'admin'
      }
      gtag("event", "reward_submitted", {
        method: "In_App",
        custom_map: {
          'role': role[me.role],
          'userName': me.name
       }
        });
      history.push(`/reward/${invoiceResponse.id}`)
    } catch (err) {
      console.log('err', err)
    }
  }

  const due_date = () => {
    var ms = new Date().getTime() + 1209600000
    var twoWeeks = new Date(ms);
    var todayDate = new Date(twoWeeks).toISOString()
    let format = todayDate.slice(0, 10) ;
    return format
  }

  useEffect(() => newItem(), [])

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.NewInvoice}>
          <div className={classes.NewInvoiceCol}>
          <h3> New Reward</h3>
            {
            //   <div className="form-row">
            //   <Input
            //     register={register}
            //     name="bill_to"
            //     label="Bill to"
            //     readOnly
            //     value="admin@tasselturn.org"
            //     placeholder="admin@tasselturn.org"
            //     errors={errors}
            //   />
            // </div>
            <div style={{minWidth: "100%", marginTop: "2vh", marginBottom: '2vh'}} className="flex justify-start flex gap-2">
            <label> Are you submitting this because you've met with a coach?</label>
            <input type="checkbox" checked={checkbox} onChange={handleCheckboxClick} register={register} name={`coachreward`} errors={errors}/>
            </div>
            }
            {fields.map((item, i) => <NewInvoice i={i} key={item.id} id={item.id} remove={remove} errors={errors} register={register}/>)}
            <div className="form-row">
              <a onClick={newItem} className="text-sm	text-blue-80">
                <i className="icon-circle-plus mr-2 text-xs"/>
                Add Goal Set
              </a>
            </div>
            <div className="form-row">
              <label>Notes</label>
              <textarea {...register('note')} errors={errors} defaultValue='N/A' className={(checkRecursive('note', errors)  ? "form-error" : "")} placeholder="Enter your notes. If no notes, add N/A"/>
            </div>
          </div>
          <div className={classes.NewInvoiceCol}>
            <h2>{me?.name} {me?.last_name}</h2>
            <div className="flex justify-between mb-3">
              { /* <label>Invoice number</label> */ }
              { /* <span>100100</span> */ }
            </div>
            <div className="form-row">
              <Input
                register={register}
                name='date'
                type="date"
                max={todaysDate}
                placeholder="Date"
                label="Reward Date"
                errors={errors}
              />
            </div>
            <div className="form-row">
              <Input
                register={register}
                min={todaysDate}
                name='due_date'
                type="date"
                readOnly
                value={due_date()}
                placeholder="Date"
                label="Reward Receive by Date"
                errors={errors}
              />
            </div>
            <InvoiceTotal control={control}/>
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <button type="button" onClick={() => history.goBack()} className="btn btn-md btn-tr">
            <i className="icon-x mr-2"/>
            Cancel
          </button>
          <button type="submit" className="btn btn-md btn-bl">
            <i className="icon-check mr-2"/>
            Create
          </button>
        </div>
      </form>
    </section>
  )
}

export default InvoiceNew
