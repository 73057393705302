export const message = {
  status: {
    SENT: 0,
    RECEIVED: 1,
    VIEWED: 2,
  },
  label: {
    0: 'Sent',
    1: 'Received',
    2: 'Viewed',
  },
}

export const LOCAL_STORAGE_ITEM_NAMES = {
  GoogleTokenExpiration: 'google_token_expiration',
  GoogleToken: 'google_token',
}
