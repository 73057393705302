import {Api} from './Api'

const GoalSetApi = Api.injectEndpoints({
  endpoints: (build) => ({
    showGoalSet: build.query({
      query: (id) => `/goal_set/${id}`,
      providesTags: ['GoalSet'],
    }),
    getGoalSet: build.query({
      query: (query) => `/goal_set?${query}`,
      providesTags: ['GoalSet'],
    }),
    destroyGoalSet: build.mutation({
      query: (id) => ({
          url: `/goal_set/${id}`,
          method: 'DELETE',
        }),
      invalidatesTags: ['GoalSet'],
    }),
    updateGoalSet: build.mutation({
      query: (data) => ({
          url: `/goal_set/${data.id}`,
          method: 'PUT',
          body: data,
        }),
      invalidatesTags: ['GoalSet'],
    }),
    storeGoalSet: build.mutation({
      query: (data) => ({
          url: '/goal_set',
          method: 'POST',
          body: data,
        }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['GoalSet'],
    }),

  }),
  overrideExisting: false,
})

export const {
  useGetGoalSetQuery,
  useShowGoalSetQuery,
  useDestroyGoalSetMutation,
  useUpdateGoalSetMutation,
  useStoreGoalSetMutation,
} = GoalSetApi
