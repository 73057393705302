import {useState} from 'react';

export const usePagination = (extraQueryParams) => {

  const ASC = 'Asc'
  const DESC = 'Desc'
  const [orderBy, setOrderBy] = useState(false)
  const [direction, setDirection] = useState(ASC)
  const [page, setPage] = useState(1)

  const handleOrderByClick = (columnName) => {
    if (columnName === orderBy) {
      setDirection(direction == ASC ? DESC : ASC)
    } else {
      setOrderBy(columnName)
    }

  }

  const params = new URLSearchParams({
    ...(orderBy && {orderBy: orderBy}),
    ...(orderBy && {direction: direction}),
    ...extraQueryParams,
    page: page
  });
  const query = params.toString()

  return {direction, orderBy, setOrderBy, query, page, setPage, handleOrderByClick, params}
}



