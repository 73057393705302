import PropTypes from 'prop-types'
import React, {useEffect, useMemo, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {usePagination} from '../../../hooks/usePagination'
import {useUserRole} from '../../../hooks/useUserRole'
import {useGetChatRoomMessagesQuery} from '../../../services/ChatRoom'
import Spinner from '../../Spinner/Spinner'
import ChatInput from '../ChatInput/ChatInput'
import ChatMessage from '../ChatMessage/ChatMessage'
import classes from './Chat.module.scss'

const infiniteScrollSelector = 'InfiniteScrollSelector'

const Chat = ({chatRoomId}) => {
  const {query, setPage} = usePagination()
  const [chats, setChats] = useState({})
  const {data: messages} = useGetChatRoomMessagesQuery({chatRoomId, query})
  const {isAdmin} = useUserRole()

  const selectedChatRoomMessages = useMemo(() => Array.isArray(chats[chatRoomId]) ? chats[chatRoomId] : [], [chatRoomId, chats])

  useEffect(
    () => {
      if (!Array.isArray(messages) || messages.length === 0 || messages.some(({roomId}) => roomId !== chatRoomId)) return

      setChats(prev => ({
        ...prev,
        [chatRoomId]: Array.isArray(prev[chatRoomId]) ? prev[chatRoomId]
          .concat(messages)
          .sort((a, b) => b.id - a.id)
          .filter((current, idx, array) => idx === 0 || current.id !== array[idx - 1]?.id)
          : [...messages],
      })
      )
    }, [chatRoomId, messages]
  )

  useEffect(() => {
    setPage(1)
  }, [chatRoomId, setPage])

  return (
    <div className={classes.Chat}>
      <div className={classes.MessagesWrapper} id={infiniteScrollSelector}>
        {Array.isArray(chats[chatRoomId]) ?
          <InfiniteScroll
            className={classes.InfiniteScroll}
            dataLength={selectedChatRoomMessages.length}
            next={() => setPage(prev => prev + 1)}
            hasMore={!Array.isArray(messages) || messages.length > 0}
            loader={<div className='overflow-hidden'><Spinner /></div>}
            scrollableTarget={infiniteScrollSelector}
            inverse
          >
            {selectedChatRoomMessages.map((chatMessage) => <ChatMessage key={`${chatMessage.id}`} message={chatMessage} />)}
          </InfiniteScroll>
          : <div className='overflow-hidden'><Spinner /></div>
        }
      </div>
      {!isAdmin &&
        <div className={classes.InputBlockWrapper}>
          <ChatInput chatRoomId={chatRoomId} />
        </div>
      }
    </div>
  )
}

Chat.propTypes = {
  chatRoomId: PropTypes.number,
}

Chat.defaultProps = {
  chatRoomId: null,
}

export default Chat
