import React from 'react'
import PropTypes from 'prop-types'
import classes from './NewMessagesBadge.module.scss'
import {useGetChatNewMessagesQuery} from '../../../../services/ChatRoom'

const maxMessageCount = 99

const NewMessagesBadge = (props) => {
  const {chatRoomId, isClicked} = props
  let {data: newMessages} = useGetChatNewMessagesQuery(chatRoomId)

  if(!newMessages) return

  let valueLabel = Math.min(newMessages, maxMessageCount)

  if (isClicked) {
    newMessages = 0
  }

  if (valueLabel !== newMessages) {
    valueLabel += '+'
  }

  return (
    <>
      {newMessages !== 0 && (
        <span className={classes.UserMessagesCount}>
          <em>{valueLabel}</em>
        </span>
      )}
    </>
  )
}

NewMessagesBadge.propTypes = {
  chatRoomId: PropTypes.number.isRequired,
  isClicked: PropTypes.bool.isRequired,
}

export default NewMessagesBadge
