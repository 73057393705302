import {createSlice} from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: "search",
  initialState: {
    students: '',
    applications: '',
    coaches: '',
    goalSet: '',
    invoice: ''

  },
  reducers: {
    storeSearchStudents: (state, param) => {
      const {payload} = param;
      state.students = payload;
    },
    storeSearchApplications: (state, param) => {
      const {payload} = param;
      state.applications = payload;
    },
    storeSearchCoaches: (state, param) => {
      const {payload} = param;
      state.coaches = payload;
    },

    storeSearchGoalSet: (state, param) => {
      const {payload} = param;
      state.goalSet = payload;
    },

    storeSearchInvoice: (state, param) => {
      const {payload} = param;
      state.invoice = payload;
    },

  }
});
const {actions, reducer} = searchSlice
export const {
  storeSearchStudents,
  storeSearchApplications,
  storeSearchCoaches,
  storeSearchGoalSet,
  storeSearchInvoice
} = actions;
export default reducer;
