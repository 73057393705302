import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classes from './ChatMessage.module.scss'
import SenderAvatar1 from '../../../../img/chat-avatar-1.jpg'
import MessageStatus from './MessageStatus/MessageStatus'
import MessageFile from './MessageFile/MessageFile'
import {useMeQuery} from '../../../services/User'

const ChatMessage = (props) => {
  const {message} = props
  const {data: me} = useMeQuery()
  const currentUserId = me?.id
  const messageBlockClasses = [classes.MessageBlock]

  if (currentUserId === message.senderId) {
    messageBlockClasses.push(classes.MessageBlockOwner)
  }

  const messageSenderName = currentUserId === message.senderId ? 'You' : message.name
  return (
    <div className={classes.MessageFrame}>
      <div className={messageBlockClasses.join(' ')}>
        <div className={classes.UserAvatar}>
          <img src={message?.avatar} className="rounded-full" alt=""/>
        </div>
        <div className={classes.MessageContent}>
          <p className={classes.UserName}>{messageSenderName}</p>
          <p className={classes.UserMessage}>{message.message}</p>
          {message?.files?.map((file) => <MessageFile {...file}/>)}
          <div className={classes.MessageInfo}>
            <span className={classes.MessageTime}>{moment(message.createdAt).format('hh:mm A')}</span>
            <MessageStatus status={message.status}/>
          </div>
        </div>
      </div>
    </div>
  )
}

ChatMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number,
    senderId: PropTypes.number,
    status: PropTypes.number,
    name: PropTypes.string,
    message: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
}

export default ChatMessage
