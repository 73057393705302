import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { LOCAL_STORAGE_ITEM_NAMES } from '../constants'

const baseUrl = 'https://www.googleapis.com/calendar/v3'
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    headers.set('authorization', `Bearer ${localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.GoogleToken)}`)
    return headers
  },
})

export const GoogleCalendarApi = createApi({
  reducerPath: 'googleCalendar',
  baseQuery,
  endpoints: (build) => ({
    getMySession: build.query({
      query: (query) => {
        const urlParams = new URLSearchParams(query)
        return `/calendars/primary/events?${urlParams.toString()}`
      },
      keepUnusedDataFor: 30,
      transformResponse: (response) => response.items,
    }),
    getMyCalendarList: build.query({
      query: () => '/users/me/calendarList',
    }),

  }),
})

export const {useGetMySessionQuery, useGetMyCalendarListQuery} = GoogleCalendarApi

