import React from 'react'
import PropTypes from 'prop-types'
import classes from './SearchInput.module.scss'

const SearchInput = (props) => {
  const {value, placeholder, onChange, cssClass} = props
  return (
    <input
      type="search"
      className={`${classes.Input} ${cssClass}`}
      defaultValue={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  cssClass: PropTypes.string,
}

SearchInput.defaultProps = {
  value: '',
  placeholder: '',
  cssClass: '',
  onChange: () => {
  },

}

export default SearchInput
