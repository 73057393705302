import {LOCAL_STORAGE_ITEM_NAMES} from '../constants'

/**
 * Set in storage
 *
 * @param {string} token - The jwt token
 *
 * @returns void
 */
export const setUserToken = (token) => localStorage.setItem('user', JSON.stringify(token))

/**
 * Get User From Storage
 *
 * @returns {object|null} - The user object
 */
export const getUserToken = () => localStorage.getItem('user')

/**
 * Get Authorization Bearer string from storage
 *
 * @returns {string} - The Bearer string
 */
export const getUserBearer = () => {
  if (Object.prototype.hasOwnProperty.call(localStorage, 'user')) {
    return `Bearer ${JSON.parse(getUserToken())}`
  }
  return ''
}


/**
 * Remove Authorization Bearer string from storage
 *
 */
export const removeUserBearer = () => localStorage.removeItem('user')

/**
 * Remove Google token string and Google token expiration date string from storage
 *
 */
export const removeGoogleToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.GoogleToken)
  localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.GoogleTokenExpiration)
}


/**
 * Set in storage
 *
 * @param {string} token - The jwt token
 *
 * @returns void
 */
export const setDrivePickerToken = (token) => {
  // eslint-disable-next-line no-param-reassign
  token.expiry = new Date(new Date().getTime() + 58 * 60000).getTime()
  localStorage.setItem('drivePickerToken', JSON.stringify(token))
}

/**
 * Get Authorization access_token string from storage
 *
 * @returns {string} - The access_token string
 */
export const getDrivePickerToken = () => {
  const token = localStorage.getItem('drivePickerToken')
  if (!token) {
    return null
  }
  const item = JSON.parse(token)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    localStorage.removeItem('drivePickerToken')
    return null
  }
  return item.access_token
}








