// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ee\\+drDrou-EHYn8r-OGUZw\\=\\={--tw-border-opacity:1;border-color:rgb(229 229 229/var(--tw-border-opacity));border-style:solid;border-top-width:1px;display:flex;justify-content:space-between;padding-top:1rem}.ee\\+drDrou-EHYn8r-OGUZw\\=\\= span{font-size:1.125rem;line-height:1.75rem}.ee\\+drDrou-EHYn8r-OGUZw\\=\\= b{font-size:1.5rem;font-weight:600;line-height:2rem}.mezTfd78\\+Z2VNTywkeekWg\\=\\={display:flex;justify-content:space-between;margin-bottom:.75rem}", "",{"version":3,"sources":["webpack://./resources/js/components/NewInvoice/InvoiceTotal/InvoiceTotal.module.scss"],"names":[],"mappings":"AACE,6BAAA,qBAAA,CAAA,sDAAA,CAAA,kBAAA,CAAA,oBAAA,CACA,YAAA,CAAA,6BAAA,CACA,gBAFA,CAKE,kCAAA,kBAAA,CAAA,mBAAA,CAIA,+BAAA,gBAAA,CAAA,eAAA,CAAA,gBAAA,CAKF,6BAAA,YAAA,CAAA,6BAAA,CAAA,oBAAA","sourcesContent":[".Total {\n  @apply border-t border-black-10 border-solid;\n  @apply flex justify-between;\n  @apply pt-4;\n\n  span {\n    @apply text-lg;\n  }\n\n  b {\n    @apply text-2xl font-semibold;\n  }\n}\n\n.Subtotal {\n  @apply flex justify-between mb-3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Total": "ee+drDrou-EHYn8r-OGUZw==",
	"Subtotal": "mezTfd78+Z2VNTywkeekWg=="
};
export default ___CSS_LOADER_EXPORT___;
