// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aSZOFM3EN5Ja4ZYi9rVU0w\\=\\={display:grid;gap:1rem;grid-template-columns:repeat(10,minmax(0,1fr));height:100%}.VfLqRRjjMvIs9M0muBOSpQ\\=\\={margin:1.5rem}._1UkOC\\+W-i0lB7RVBIdCOMw\\=\\={--tw-bg-opacity:1;background-color:rgb(255 255 255/var(--tw-bg-opacity));border-radius:1.5rem;grid-column:span 3/span 3}.QdxM-zFOV9tEpYu50gspHQ\\=\\={margin-bottom:1.5rem}.Sc-duZi1beWpsgP6huUMZw\\=\\={display:grid}", "",{"version":3,"sources":["webpack://./resources/js/components/chat/Messages/Messages.module.scss"],"names":[],"mappings":"AAKE,4BAAA,YAAA,CAEA,QAAA,CADA,8CAAA,CAEA,WAHA,CAOA,4BAAA,aAAA,CAIA,8BACA,iBAAA,CAAA,sDAAA,CACA,oBAAA,CAFA,yBAAA,CAMA,4BAAA,oBAAA,CAIA,4BAAA,YAAA","sourcesContent":[".Container {\n\n}\n\n.Columns {\n  @apply grid;\n  @apply grid-cols-10;\n  @apply gap-4;\n  @apply h-full;\n}\n\n.Wrapper {\n  @apply m-6;\n}\n\n.Menu {\n  @apply col-span-3;\n  @apply bg-white;\n  @apply rounded-3xl;\n}\n\n.SearchBox {\n  @apply mb-6;\n}\n\n.ChatInputWrapper {\n  @apply grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Columns": "aSZOFM3EN5Ja4ZYi9rVU0w==",
	"Wrapper": "VfLqRRjjMvIs9M0muBOSpQ==",
	"Menu": "_1UkOC+W-i0lB7RVBIdCOMw==",
	"SearchBox": "QdxM-zFOV9tEpYu50gspHQ==",
	"ChatInputWrapper": "Sc-duZi1beWpsgP6huUMZw=="
};
export default ___CSS_LOADER_EXPORT___;
