import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import {getUserBearer} from './user'

const url = new URL(process.env.MIX_APP_URL)
// const wsUrl = new URL(process.env.MIX_WS_URL)

const options = {
  // wsHost: wsUrl.hostname,
  authEndpoint: `${url.origin}/broadcasting/auth`,
  key: process.env.MIX_PUSHER_APP_KEY,
  forceTLS: true,
  disableStats: true,
  encrypted: true,
  auth: {
    headers: {
      Authorization: getUserBearer(),
      Accept: 'application/json',
    },
  },
}



const EchoClient = new Echo({
  broadcaster: 'pusher',
  client: new Pusher(options.key, options),
})

export default EchoClient
