import PropTypes from 'prop-types'
import React from 'react'
import {NavLink} from 'react-router-dom'
import {ADMIN, COACH, STUDENT} from '../../utils/userRoles'
import classes from './Sidebar.module.scss'

const menu = [
    {
        iconClassName: 'icon-grid',
        label: 'Dashboard',
        pathname: '/',
        availableForRoles: [STUDENT, COACH],
    },
    {
        iconClassName: 'icon-documents',
        label: 'Reward Center',
        pathname: '/rewards',
        availableForRoles: [STUDENT, ADMIN],
    },
    {
        iconClassName: 'icon-school',
        label: 'Students',
        pathname: '/my-students',
        availableForRoles: [COACH],
    },
    {
        iconClassName: 'icon-chat',
        label: 'Messages',
        pathname: '/messages',
        availableForRoles: [STUDENT, COACH],
    },
    {
        iconClassName: 'icon-people',
        label: 'Available Coaches',
        pathname: '/available-mentors',
        availableForRoles: [STUDENT],
    },
    {
        iconClassName: 'icon-calendar',
        label: 'My Sessions',
        pathname: '/my-sessions',
        availableForRoles: [STUDENT],
    },
    {
        iconClassName: 'icon-people',
        label: 'Coaches',
        pathname: '/',
        availableForRoles: [ADMIN],
    },
    {
        iconClassName: 'icon-school',
        label: 'Students',
        pathname: '/students',
        availableForRoles: [ADMIN],
    },
    {
        iconClassName: 'icon-document',
        label: 'Applications',
        pathname: '/applications',
        availableForRoles: [ADMIN],
    },
    {
        iconClassName: 'icon-target',
        label: 'Goal Sets',
        pathname: '/admin-goal-sets',
        availableForRoles: [ADMIN],
    },
    {
        iconClassName: 'icon-documents',
        label: 'Leaderboards',
        pathname: '/leaderboards/rewards',
        availableForRoles: [STUDENT, ADMIN, COACH],
    },
]


const Menu = ({role}) =>
    !!role &&
    <div className={classes.MenuArea}>{menu
        .filter(({availableForRoles}) => availableForRoles.includes(role))
        .map(({iconClassName, label, pathname}) =>
            <NavLink key={`${label}-${pathname}`} to={pathname} activeClassName="item-active" isActive={(match, location) => match?.url === location.pathname}>
                <i className={iconClassName} />
                <span>{label}</span>
            </NavLink>
        )}
    </div>

Menu.defaultProps = {
    role: null,
}

Menu.propTypes = {
    role: PropTypes.oneOf([ADMIN, COACH, STUDENT]),
}

export default Menu
