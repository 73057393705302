// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../img/icons/icon-search.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T7vb-yHAoniFu6CuIOA85Q\\=\\={background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat scroll 15px 8px;border:1px solid #e5e5e5;border-radius:1.5rem;color:#000;font-size:16px;font-weight:400;height:40px;line-height:24px;padding:8px 16px 8px 44px;width:100%}.T7vb-yHAoniFu6CuIOA85Q\\=\\=::-moz-placeholder{color:#999;opacity:1}.T7vb-yHAoniFu6CuIOA85Q\\=\\=::placeholder{color:#999;opacity:1}", "",{"version":3,"sources":["webpack://./resources/js/components/UI/SearchInput/SearchInput.module.scss"],"names":[],"mappings":"AACE,4BASA,4EAAA,CALA,wBAAA,CAJA,oBAAA,CAKA,UAAA,CAEA,cAAA,CADA,eAAA,CAHA,WAAA,CAKA,gBAAA,CANA,yBAAA,CADA,UADA,CAWA,8CACE,UAAA,CACA,SACJ,CAHE,yCACE,UAAA,CACA,SACJ","sourcesContent":[".Input {\n  @apply rounded-3xl;\n  @apply w-full;\n  padding: 8px 16px 8px 44px;\n  height: 40px;\n  border: 1px solid #E5E5E5;\n  color: #000000;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  background: url('../../../../img/icons/icon-search.svg') no-repeat scroll 15px 8px;\n\n  &::placeholder {\n    color: #999999;\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Input": "T7vb-yHAoniFu6CuIOA85Q=="
};
export default ___CSS_LOADER_EXPORT___;
