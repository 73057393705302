import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import classes from './Header.module.scss'
import {useMeQuery} from '../../services/User'
import {useUserRole} from '../../hooks/useUserRole'
import PopupAccount from '../PopupAccount/PopupAccount'
import HeaderComponents from './HeaderComponents'
import ReportIssue from '../ReportIssue/ReportIssue'
import {Helmet} from "react-helmet";


const Header = ({pageName, pageTitle}) => {
  const {data: me} = useMeQuery()
  const {title, reverse} = useSelector(state => state.header)
  const [isOpen, setOpen] = useState(false)
  const {isStudent} = useUserRole()
  const onClick = () => {
    isOpen ? setOpen(false) : setOpen(true);
  }
  return (
    <div className={classes.Header}>
      <div className={reverse ? classes.Reverse : classes.Normal}>
      {title && <h1>{title} </h1>}
      <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
        </Helmet>
      <HeaderComponents pageName={pageName} pageTitle={pageTitle}/>
     </div>
      <div className={classes.HeadRight}>
        <ReportIssue />
        {/*{ !isStudent &&*/}
        {/*  <a href="#" className={classes.UserNotifications}>*/}
        {/*    <i className="icon-bell text-base"/>*/}
        {/*    <span className={classes.Counter}>2</span>*/}
        {/*  </a>*/}
        {/*}*/}
        <div className={classes.PopupWrapper}>
          <a href="#" onClick={onClick} className={classes.UserDetails}>
            <img src={me?.avatar} alt=""/>
            <span>{me?.email}</span>
            <i className={isOpen ? 'icon-chevron-down text-xs rotate' : 'icon-chevron-down text-xs'}/>
          </a>
          {isOpen && <PopupAccount/>}
        </div>
      </div>
    </div>
  )
}

export default Header
