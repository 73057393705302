import React from 'react'
import PropTypes from 'prop-types'
import classes from '../../Chat/Chat.module.scss'

const ChatInputFile = (props) => {
  const {name, index, calback} = props

  const remove = () => {
    calback(index)
  }
  return (
    <div className={classes.File}>
      <span>{name}</span>
      <i className="icon-file-check" />
      <a onClick={remove}>
        <i className="icon-trash" />
      </a>
    </div>
  )
}

ChatInputFile.propTypes = {
  name: PropTypes.string,
}

export default ChatInputFile
