import {Api} from './Api'

const ReportApi = Api.injectEndpoints({
  endpoints: (build) => ({
    reportCoach: build.mutation({
      query: (data) => {
        return {
          url: '/report/coach',
          method: 'POST',
          body: data,
        }
      },
    }),
    reportIssue: build.mutation({
      query: (data) => {
        return {
          url: '/report/issue',
          method: 'POST',
          body: data,
        }
      },

    }),
  }),

})

export const {useReportIssueMutation, useReportCoachMutation,} = ReportApi
