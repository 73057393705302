import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {ErrorBoundary} from 'react-error-boundary'
import {ToastContainer} from 'react-toastify'
import {GoogleOAuthProvider} from '@react-oauth/google'
import store from './store'
import Routes from './routes'
import ErrorFallback from './components/ErrorFallback/ErrorFallback'
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.MIX_GOOGLE_CLIENT_ID}>
        <Routes />
      </GoogleOAuthProvider>
    </Provider>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      closeOnClick
    />
  </ErrorBoundary>
)
