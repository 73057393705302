import React, {useState} from 'react'
import PropTypes from 'prop-types'
import NewMessagesBadge from './NewMessagesBadge/NewMessagesBadge'
import classes from './UserItem.module.scss'

const UserItem = (props) => {
  const {chatRoom, active, onChange} = props
  const userItemClasses = [classes.UserItem]
  const [isClicked, setIsClicked] = useState(false)
  const onClick = () => {
    onChange(chatRoom.id);
    setIsClicked(true)
  }

  if (active) {
    userItemClasses.push(classes.UserItemActive)
  }

  return (
    <button type="button" className={classes.Button} onClick={onClick}>
      <div key={chatRoom.id} className={userItemClasses.join(' ')}>
        <div className={classes.UserImage}>
          {chatRoom.avatar && (
            <div className={classes.UserImageWrapper}>
              <img src={chatRoom.avatar} alt={chatRoom.name} />
            </div>
          )}
        </div>
        <div className={classes.UserInfoBox}>
          <p className={classes.UserNameBox}>
            <span className={classes.UserName}>{chatRoom.name}</span>
            <span className={classes.LastMessageTime}>{chatRoom.lastMessageTime}</span>
          </p>
          <p className={classes.UserPositionBox}>
            <span className={classes.UserPosition}>{chatRoom.position}</span>
            <NewMessagesBadge isClicked={isClicked} chatRoomId={chatRoom.id} />
          </p>
          <p className={classes.UserDescription}>{chatRoom.description}</p>
        </div>
      </div>
    </button>
  )
}

UserItem.propTypes = {
  chatRoom: PropTypes.shape({
    id: PropTypes.number,
    associateId: PropTypes.number,
    avatar: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    description: PropTypes.string,
    lastMessageTime: PropTypes.string,
    newMessagesCount: PropTypes.number,
    active: PropTypes.bool,
  }),
  active: PropTypes.bool,
  onChange: PropTypes.func,
}

UserItem.defaultProps = {
  chatRoom: {},
  active: false,
  onChange: () => {},
}

export default UserItem
