import React from 'react'
import PropTypes from 'prop-types'
import * as constants from '../../../../constants'

const MessageStatus = (props) => {
  const {status} = props
  let strokeColor = '#808080'

  if (constants.message.status.VIEWED <= status) {
    strokeColor = '#194AE5'
  }

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.66602 7.99935L7.99935 11.3327L14.666 4.66602" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
      {constants.message.status.SENT < status && <path d="M8.00065 7.99935L11.334 4.66602M1.33398 7.99935L4.66732 11.3327L1.33398 7.99935Z" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />}
    </svg>
  )
}

MessageStatus.propTypes = {
  status: PropTypes.number,
}

MessageStatus.defaultProps = {
  status: constants.message.status.SENT,
}

export default MessageStatus
