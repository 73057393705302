// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NQwicYb4ElqBStqvGFThUA\\=\\={--tw-bg-opacity:1;align-items:center;background-color:rgb(255 255 255/var(--tw-bg-opacity));border-radius:1.5rem;display:flex;grid-column:span 7/span 7;justify-content:center}.LAZMTJQj-A7ohoMg1p7A-w\\=\\={height:auto}", "",{"version":3,"sources":["webpack://./resources/js/components/chat/ChatEmpty/ChatEmpty.module.scss"],"names":[],"mappings":"AACE,4BAEA,iBAAA,CAFA,kBAAA,CAEA,sDAAA,CACA,oBAAA,CAHA,YAAA,CACA,yBAAA,CADA,sBAAA,CAOA,4BAAA,WAAA","sourcesContent":[".ChatEmpty {\n  @apply flex justify-center items-center;\n  @apply col-span-7;\n  @apply bg-white;\n  @apply rounded-3xl;\n}\n\n.ChatEmptyWrapper {\n  @apply h-auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChatEmpty": "NQwicYb4ElqBStqvGFThUA==",
	"ChatEmptyWrapper": "LAZMTJQj-A7ohoMg1p7A-w=="
};
export default ___CSS_LOADER_EXPORT___;
