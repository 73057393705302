import React, {useMemo} from 'react'
import {useWatch} from 'react-hook-form'
import {object} from 'prop-types'
import classes from './InvoiceTotal.module.scss'

const InvoiceTotal = ({control}) => {
  const items = useWatch({control, name: 'items'})
  const total = useMemo(() => calculate(items), [items])
  return (
    <>
      <div className={classes.Subtotal}>
        <label>Subtotal:</label>
        <span>${total}</span>
      </div>
      <div className={classes.Total}>
        <span>Total</span>
        <b>${total}</b>
      </div>
    </>
  )
}

const calculate = (items) => {
  return items?.reduce((previous, current) => {
    let qty = isNaN(current.qty) ? 0 : current.qty
    let price = isNaN(current.price) ? 0 : current.price
    return previous + qty * price
  }, 0)
}

InvoiceTotal.propTypes = {
  control: object.isRequired
}
export default InvoiceTotal

