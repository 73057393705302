import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import {Api} from '../services/Api'
import {GoogleCalendarApi} from '../services/GoogleCalendarApi'
import {GoogleDriveApi} from '../services/GoogleDriveApi'
import calendar from '../slices/calendarSlice'
import header from '../slices/headerSlice'
import search from '../slices/searchSlice'
import {ErrorLogger} from './ErrorLogger'

const store = configureStore({
  reducer: {
    [Api.reducerPath]: Api.reducer,
    [GoogleCalendarApi.reducerPath]: GoogleCalendarApi.reducer,
    [GoogleDriveApi.reducerPath]: GoogleDriveApi.reducer,
    header,
    search,
    calendar,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([Api.middleware, ErrorLogger, GoogleCalendarApi.middleware, GoogleDriveApi.middleware]),
})

setupListeners(store.dispatch)

export default store
