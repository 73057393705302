import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getUserBearer} from '../utils/user'

const baseUrl = process.env.MIX_API_URL
const formDataEndpoints = ['avatar', 'storeChatMessage']
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, {endpoint}) => {
    const bearerToken = getUserBearer();
    if (bearerToken) {
      headers.set('authorization', bearerToken);
    }
    if (!formDataEndpoints.includes(endpoint)) {
      headers.set('Content-Type', 'application/json')
      headers.set('Accept', 'application/json')
    }
    return headers
  },
})

// initialize an empty api service that we'll inject endpoints into later as needed
export const Api = createApi({
  baseQuery,
  tagTypes: [
    'MoodEntry',
    'Me',
    'GoalSet',
    'ChatRoom',
    'Goal',
    'Coach',
    'Invoice',
    'Application',
    'Student',
    'StudentGoalSet',
    'Sessions',
  ],
  endpoints: () => ({}),
})
