import {Api} from './Api'

const UserApi = Api.injectEndpoints({
  endpoints: (build) => ({
    me: build.query({
      query: () => '/me',
      providesTags: ['Me'],
    }),
    getUserByEmail: build.query({
      query: (email) => `/user_by_email/${email}`,
      transformResponse: (response) => response.data,
    }),
    login: build.mutation({
      query: (payload) => {
        const data = {'access_token': payload}
        return {
          url: '/login',
          method: 'POST',
          body: data,
        }
      },
    }),
    avatar: build.mutation({
      query: (payload) => ({
        url: '/avatar',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Me'],
    }),
  }),
  overrideExisting: false,
})

export const {useMeQuery, useLoginMutation, useAvatarMutation, useGetUserByEmailQuery} = UserApi
