import React from 'react'
import {useParams} from 'react-router-dom'
import Messages from '../../components/chat/Messages/Messages'

const ChatLogs = () => {
  const {userId} = useParams()
  return (
    <Messages userId={userId} />
  )
}

export default ChatLogs
