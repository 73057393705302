import React from 'react'
import classes from './ChatEmpty.module.scss'

const ChatEmpty = () => (
  <div className={classes.ChatEmpty}>
    <p className={classes.ChatEmptyWrapper}>
      Select a chat to start messaging
    </p>
  </div>
)

export default ChatEmpty
