import React from 'react'
import PropTypes from 'prop-types'
import classes from './UserList.module.scss'
import UserItem from '../UserItem/UserItem'

const UserList = (props) => {
  const {list, activeChatRoomId, onChange} = props

  return (
    <div className={classes.UserList}>
      {!list.length && <div style={{textAlign: 'center'}}>No chat rooms found</div>}

      {list.map(chatRoom => {
        const isActive = chatRoom.id === activeChatRoomId

        return <UserItem key={chatRoom.id} active={isActive} chatRoom={chatRoom} onChange={onChange} />
      })}
    </div>
  )
}

UserList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    position: PropTypes.string,
    description: PropTypes.string,
    lastMessageTime: PropTypes.string,
    newMessagesCount: PropTypes.number,
    avatar: PropTypes.string,
    active: PropTypes.bool,
  })),
  activeChatRoomId: PropTypes.number,
  onChange: PropTypes.func,
}

UserList.defaultProps = {
  list: [],
  activeChatRoomId: null,
  onChange: () => {},
}

export default UserList
