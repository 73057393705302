import {useGoogleLogin} from '@react-oauth/google'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Redirect, Route, useHistory, useLocation} from 'react-router-dom'
import {LOCAL_STORAGE_ITEM_NAMES} from '../../constants'
import {useModal} from '../../hooks/useModal'
import {useMeQuery} from '../../services/User'
import {addHours} from '../../utils/helper'
import Modal from '../Modal/Modal'
import Spinner from '../Spinner/Spinner'
import MainLayout from './MainLayout'

const SESSION_EXPIRATION_THRESHOLD_MS = 5 * 60 * 1000

const SessionExpiredModal = () => {
  const {openModal, closeModal, modalState} = useModal(false)
  const [googleSessionState, setGoogleSessionState] = useState({
    isLoading: true,
    expirationDate: null,
  })
  const history = useHistory()

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      // eslint-disable-next-line no-console
      const googleTokenExpirationDate = addHours({date: new Date(), hours: 1})

      localStorage.setItem(LOCAL_STORAGE_ITEM_NAMES.GoogleToken, tokenResponse?.access_token)
      localStorage.setItem(LOCAL_STORAGE_ITEM_NAMES.GoogleTokenExpiration, googleTokenExpirationDate)

      setGoogleSessionState(prev => ({...prev, isLoading: false, expirationDate: googleTokenExpirationDate}))
      history.go(0)
    },
    onError: () => setGoogleSessionState(prev => ({...prev, isLoading: false})),
    onNonOAuthError: () => setGoogleSessionState(prev => ({...prev, isLoading: false})),
    scope: 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.events',
  })

  useEffect(() => {
    const googleTokenExpiration = localStorage.getItem(LOCAL_STORAGE_ITEM_NAMES.GoogleTokenExpiration)
    setGoogleSessionState(prev => ({...prev, isLoading: false, expirationDate: googleTokenExpiration ? new Date(googleTokenExpiration) : null}))
  }, [])

  useEffect(() => {
    if (googleSessionState.isLoading) return

    const isSessionExpired = !googleSessionState.expirationDate || googleSessionState.expirationDate.getTime() <= Date.now() + SESSION_EXPIRATION_THRESHOLD_MS

    if (isSessionExpired) {
      openModal()
      return
    }

    closeModal()

    const sessionExpiredModalTimeout = setTimeout(() => {
      openModal()
    }, googleSessionState.expirationDate.getTime() - Date.now() - SESSION_EXPIRATION_THRESHOLD_MS)

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(sessionExpiredModalTimeout)
    }
  }, [openModal, closeModal, googleSessionState, history])

  return (
    <Modal modalState={modalState} close={closeModal} canBeClosed={false}>
      <div className='flex flex-col gap-4'>
        <p>Your google session has expired, please log in again</p>
        <button type="button" className="btn btn-lg btn-black lg:w-full mb-6" onClick={() => {
          setGoogleSessionState(prev => ({...prev, isLoading: true}))
          googleLogin()
        }}>
          <i className="icon-google" />
          <span>Continue with Google</span>
        </button>
      </div>
    </Modal>
  )
}

function withGoogleAnalyticsTracking(Component, me) {
  return function WrappedComponent(props) {
    const location = useLocation();

    useEffect(() => {
      window.gtag('config', 'G-G568PHRW5G', {
        page_path: location.pathname,
      });

    }, [location.pathname]);

    return <Component {...props} />;
  };
}

const MainLayoutRoute = ({allowedRoles, pageTitle, render, ...rest}) => {
  const {data: me, isLoading, error} = useMeQuery()

  let redirectTo = '/login'
  let isAllowed = true
  const location = useLocation();

  useEffect(() => {
    if (me && me.id) {

      let role = {
        1: 'student',
        2: 'coach',
        3: 'admin'
      }

      gtag('set', "user_properties", {
        'role': role[me.role]
      });

     gtag('config', 'G-G568PHRW5G', {
       page_path: location.pathname,
       'role': role[me.role],
     })
  
   } else {
    gtag('config', 'G-G568PHRW5G', {
       page_path: location.pathname
     })
   }
   document.title = pageTitle;

  }, [location.pathname, pageTitle, me]);

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    isAllowed = false
  }

  if (allowedRoles && allowedRoles.length > 0 && !allowedRoles.includes(me?.role)) {
    isAllowed = false
    redirectTo = '/403'
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          {isAllowed && <>
            <SessionExpiredModal />
            <MainLayout pageTitle={pageTitle} {...rest}>
              {render(matchProps)}
              </MainLayout></>}
          {!isAllowed && <Redirect to={redirectTo} />}
        </>
      )}
    />
  )
}

MainLayoutRoute.defaultProps = {
  allowedRoles: [],
}

MainLayoutRoute.propTypes = {
  render: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allowedRoles: PropTypes.array,
}

export default MainLayoutRoute
